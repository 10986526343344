import WizardController from '../../wizard_controller'

export default class extends WizardController {

  static targets = [
    ...super.targets,
    'drinksDetails',
    'nestedTextInput',
    'nestedTextWrapper',
    'nestedTextTemplate',
    'itemDetails',
    'radioButtonDetails',
    'sectionDetailsNoneOfTheAbove',
    'sectionDetailsNoneOfTheAboveCheckbox',
    'sectionNoneOfTheAbove',
    'singleCheckBoxDetails',
    'toggleShowFor'
  ]

  static values = {
    conditionNone: String,
    sectionItem:   String
  }

  // ==== Actions

  // === Checkboxes

  toggleSectionNoneOfTheAbove({ currentTarget }) {
    this.toggleCheckboxNoneOfTheAbove({
      input:         currentTarget,
      targets:       this.sectionNoneOfTheAboveTargets,
      noneValue:     this.conditionNoneValue,
      dispatchInput: true
    })
  }

  toggleSectionItemDetails({ currentTarget }) {
    const detailsSection = currentTarget.dataset.detailsSection
    this.toggleCheckboxMoreDetails({
      input:  currentTarget,
      target: this.itemDetailsTargets.find((target) => target.dataset.detailsSection === detailsSection)
    })
  }

  toggleSectionDetailsNoneOfTheAbove({ currentTarget: { checked }, params: { targetType } }) {
    if (!checked) return

    if (targetType === 'none') {
      const notNoneTargets = this.sectionDetailsNoneOfTheAboveTargets
      notNoneTargets.forEach((target) => {
        this.toggleChecked(target)
      })
    } else {
      const noneInput = this.sectionDetailsNoneOfTheAboveCheckboxTarget
      this.toggleChecked(noneInput)
    }
  }

  toggleSingleCheckBoxDetails({ currentTarget }) {
    this.toggleNoneInverseSelection({
      input:  currentTarget,
      target: this.singleCheckBoxDetailsTarget
    })
  }

  // === Radio buttons show more details

  toggleRadioButtonDetails({ currentTarget }) {
    const detailsSection = currentTarget.dataset.detailsSection
    const expandOn       = currentTarget.dataset.expandOn
    if (currentTarget.value === expandOn) {
      this.showRadioButtonMoreDetails({
        target: this.radioButtonDetailsTargets.find((target) => target.dataset.detailsSection === detailsSection)
      })
    } else {
      this.hideRadioButtonMoreDetails({
        target: this.radioButtonDetailsTargets.find((target) => target.dataset.detailsSection === detailsSection)
      })
    }
  }

  // === Nested inputs

  addNestedTextInputItem() {
    this.addNestedForm({
      list:      this.nestedTextInputTargets,
      inputType: "input[type='radio'], input[type='text']",
      wrapper:   this.nestedTextWrapperTarget
    })
  }

  removeNestedTextInputItem({ currentTarget }) {
    this.removeNestedForm({
      button:  currentTarget,
      wrapper: this.nestedTextWrapperTarget,
      list:    this.nestedTextInputTargets
    })
  }

  // ==== Templates

  toggleSymptom({ currentTarget }) {
    const attributeGroupName         = currentTarget.dataset.attributeGroupName
    const attributeValue             = currentTarget.value
    const attributeGroupElements     = this.toggleShowForTargets.filter((target) => target.dataset.attributeGroup === attributeGroupName)
    const attributeGroupShowElements = this.toggleShowForTargets.filter(
      (target) => target.dataset.templateShowFor === `${attributeGroupName}_${attributeValue}`
    )

    this.hideTargets(attributeGroupElements)
    this.showTargets(attributeGroupShowElements)
  }

  hideTargets(targets) {
    targets.forEach((target) => this.hideTarget(target))
  }

  showTargets(targets) {
    targets.forEach((target) => this.showTarget(target))
  }

  // ==== Shared

  toggleChecked(target) {
    const wasChecked  = target.checked
    target.checked = false

    if (wasChecked) {
      target.dispatchEvent(new Event('change', { bubbles: true }))
    }
  }

}
