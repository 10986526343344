import ApplicationController from '../../../support/application_controller'

export default class extends ApplicationController {

  static outlets = [
    'consultation'
  ]

  connect() {
    this.jumpToFirstError()
  }

  jumpToFirstError() {
    const firstError = this.element.querySelector('.a-ds2-input__error')
    if (!firstError) {
      if (this.hasConsultationOutlet) {
        this.consultationOutlet.scrollToTop()
      }
      return
    }

    firstError.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

}
