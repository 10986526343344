import { get }          from '@rails/request.js'
import BmiCalculator    from '../../../../support/bmi_calculator'
import WizardController from '../../../wizard_controller'
import WizardMixin      from '../../../../support/wizard_mixin'

export default class extends WizardMixin(WizardController) {

  static targets = [
    ...super.targets,
    'bloodPressureDiastolic',
    'bloodPressureSystolic',
    'bmi',
    'height',
    'hivConfirmationTestResultInconclusive',
    'hivConfirmationTestResultReactive',
    'hivTestResult',
    'repeatBloodPressure',
    'urineTestStrip',
    'weight'
  ]

  static values = {
    ...super.values,
    rangeCheckUrl: String
  }

  static outlets = [
    'consultation-note'
  ]

  connect() {
    BmiCalculator(this)
  }

  // ==== Controllers

  // ==== Actions

  // === Radio Buttons

  toggleHivTestTaken(event) {
    if (event.currentTarget.value === 'patient_tested_today') {
      this.showRadioButtonMoreDetails({
        target: this.hivTestResultTarget
      })
    } else {
      this.hideRadioButtonMoreDetails({
        target: this.hivTestResultTarget
      })
    }
  }

  toggleHivTestResult(event) {
    [
      { value: 'reactive', target: this.hivConfirmationTestResultReactiveTarget },
      { value: 'inconclusive', target: this.hivConfirmationTestResultInconclusiveTarget }
    ].forEach((obj) => {
      if (event.currentTarget.value === obj.value) {
        this.showRadioButtonMoreDetails({
          target: obj.target
        })
      } else {
        this.hideRadioButtonMoreDetails({
          target: obj.target
        })
      }
    })
  }

  toggleUrineTestStrip({ currentTarget }) {
    this.toggleTarget(this.urineTestStripTarget, currentTarget.checked)
  }

  // === Escalation

  async checkBloodPressureRange(event) {
    const queryData = new FormData()

    this.consultationNoteOutlet.autoSave(event)

    queryData.append(this.bloodPressureDiastolicTarget.name, this.bloodPressureDiastolicTarget.value)
    queryData.append(this.bloodPressureSystolicTarget.name, this.bloodPressureSystolicTarget.value)

    const response = await get(
      this.rangeCheckUrlValue,
      {
        query:        queryData,
        responseKind: 'turbo-stream'
      }
    )

    this.toggleTarget(this.repeatBloodPressureTarget, response.statusCode === 200)
  }

  // === Private

}
