import BaseController from '../base_controller'

export default class extends BaseController {

  static targets = [
    ...super.targets,
    'patientAddress'
  ]

  togglePatientAddress({ currentTarget }) {
    if (currentTarget.value === 'ampath') {
      this.showTarget(this.patientAddressTarget)
    } else {
      this.hideTarget(this.patientAddressTarget)
    }
  }

}
