import VaccineController from '../../vaccine_controller'
import WizardMixin       from '../../../../support/wizard_mixin'

export default class extends WizardMixin(VaccineController) {

  static targets = [
    ...super.targets,
    'influenzaAdministeredDetails',
    'influenzaNotAdministeredDetails',
    'medicalReasonDetails',
    'otherReasonDetails'
  ]

  setCurrentState() {
    this.historicalFieldTargets.forEach((target) => {
      this.hideTarget(target)
    })

    this.currentFieldTargets.forEach((target) => {
      this.showTarget(target)
    })
  }

  setHistoricalState() {
    this.historicalFieldTargets.forEach((target) => {
      this.showTarget(target)
    })

    this.currentFieldTargets.forEach((target) => {
      this.hideTarget(target)
    })
  }

  toggleInfluenzaAdministeredDetails({ currentTarget }) {
    this.toggleNoneInverseSelection({
      input:  currentTarget,
      target: this.influenzaAdministeredDetailsTarget
    })

    this.toggleCheckboxMoreDetails({
      input:  currentTarget,
      target: this.influenzaNotAdministeredDetailsTarget
    })
  }

  toggleInfluenzaNotAdministeredReasonDetails({ currentTarget }) {
    [
      { value: 'medical_reason', target: this.medicalReasonDetailsTarget },
      { value: 'other', target: this.otherReasonDetailsTarget }
    ].forEach((obj) => {
      if (currentTarget.value === obj.value) {
        this.showRadioButtonMoreDetails({
          target: obj.target
        })
      } else {
        this.hideRadioButtonMoreDetails({
          target: obj.target
        })
      }
    })
  }

  get hideClass() {
    return 'u-hide'
  }

}
