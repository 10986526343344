import ApplicationController from '../../support/application_controller'
import { enableElement } from '@/support/helpers'

export default class extends ApplicationController {

  static targets = []

  enableConsultationDropdown() {
    document.querySelectorAll('.c-dropdown, .-is-loading').forEach((dropdown) => {
      enableElement(dropdown.querySelector('.c-button'))

      dropdown.querySelectorAll('.c-dropdown-option').forEach((button) => {
        enableElement(button)
      })

      dropdown.classList.remove('-is-loading')
    })
  }

  // ==== Getters

  // ==== Setters

}
