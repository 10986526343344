import debounce                     from 'lodash/debounce'
import morphdom                     from 'morphdom'
import ApplicationController        from '../support/application_controller'
import { autoReFocus, isFileInput } from '../support/helpers'

let focusField
let autoSaveTriggered

export default class extends ApplicationController {

  static targets = [
    'autoSave',
    'manualSave'
  ]

  static values = {
    autoSaveEnabled:  Boolean,
    autoSaveDuration: Number
  }

  static outlets = [
    'consultation'
  ]

  initialize() {
    this.manualSaveTriggered   = false
    this.autoSaveEnabledValue  = true
    this.autoSaveDurationValue ||= 8000

    this.autoSave = debounce(this.autoSave, this.autoSaveDurationValue)
  }

  // ==== Actions

  ajaxComplete(event) {
    const [_response, _status, xhr] = event.detail

    this.autoSave.cancel()
    this.renderFlashMessage(xhr)

    if (event.currentTarget.dataset.morph === 'true') {
      morphdom(this.consultationOutlet.responseBodyTarget, `<div>${xhr.response}</div>`, {
        childrenOnly: true,
        onBeforeElUpdated(fromEl, toEl) {
          if (isFileInput(fromEl) && isFileInput(toEl)) return false
          return true
        }
      })
    } else {
      this.consultationOutlet.responseBodyTarget.innerHTML = xhr.response
    }

    this.consultationOutlet.switchActions()

    if (autoSaveTriggered) {
      autoReFocus(focusField)
    }

    if (xhr.response === '') {
      this.consultationOutlet.closeInframe()
    } else if (event.currentTarget.dataset.scrollToTop !== 'false') {
      this.consultationOutlet.scrollToTop()
    }

    if (xhr.status === 422) {
      setTimeout(() => {
        const firstErrorInput = this.consultationOutlet.responseBodyTarget.querySelector('.a-ds2-input.-error input')
        if (firstErrorInput) {
          firstErrorInput.focus()
          firstErrorInput.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      })
    }
  }

  autoSave(event) {
    if (this.autoSaveEnabledValue && !this.manualSaveTriggered) {
      focusField = event.target.id
      autoSaveTriggered = true
      this.autoSaveTarget.click()
    }
  }

  manualSave() {
    autoSaveTriggered        = false
    this.manualSaveTriggered = true
  }

  // ==== Getters

  // ==== Setters

  // ==== Private

  // ==== Channels

}
